<template>
<div class="container">
  <div class="margin-top-100"></div>
  <div class="row">
    <div class="col-md-12">
      <div class="login-page-title">
        注册
      </div>
    </div>
    <div class="col-xs-12 col-sm-2 col-md-3"></div>
    <div class="col-xs-12 col-sm-8 col-md-6">

      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <input type="text" class="form-control" v-model="username" id="username" placeholder="用户名" required>
        </div>

        <div class="form-group">
          <input type="email" class="form-control" v-model="email" id="email" placeholder="邮箱" required>
          <small id="title" class="form-text text-muted" v-show="!isEmail"><span class="red">{{emailMsg}}</span></small>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <input type="tel" class="form-control verification-code-left" v-model="code" maxlength="6" placeholder="验证码" required @input="complete" />
            </div>
            <div class="col-md-6">
              <el-button class="btn submit-right verification-code-right margin-left-10" @click="send" v-if="isSend">发送验证码</el-button>
              <el-button class="btn submit-right verification-code-right" v-if="!isSend">{{sendmsg}}</el-button>
              <small id="title" class="form-text text-muted" v-show="!isCode"><span class="red">{{codeMsg}}</span></small>
            </div>
          </div>
        </div>

        <div class="form-group">
          <el-input type="password" v-model="password" minlength="6" placeholder="密码" required show-password></el-input>
        </div>

        <div class="form-group">
          <el-input type="password" v-model="passwordConfirm" minlength="6" placeholder="确认密码" required show-password></el-input>
        </div>

        <button type="submit" class="btn btn-primary submit-center">提交</button>
        
      </form>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default {
  name: 'Register',
  data() {
    return {
      username: '',
      code: '',
      email: '',
      password: '',
      passwordConfirm: '',
      isSend: true,
      sendmsg: '发送验证码',
      isCode: true,
      codeMsg: '',
      isEmail: true,
      emailMsg: '',
    }
  },
  methods: {
    tigs(msg, type) {
      ElMessage({
        message: msg,
        type: type,
      })
    },
    send() { 
      if (!this.email) {
        this.tigs('请填写邮箱', 'warning')
      // } else if (/^1(3|4|5|6|7|8)\d{9}$/.test(this.email) == false) {
        // this.tigs('请填写正确的手机号！', 'warning')
      } else {
        this.sendCode()
      }
    },
    async sendCode() {
      const response = 
      await axios.post('/api/web/sendVerificationCodeByRegister', {
        email: this.email,
      });
      // console.log(response)
      if(response.data.code == 200) {
        this.reciprocal()
      }
      this.isEmail = false
      this.emailMsg = response.data.message
    },
    reciprocal() { 
      this.isSend = false;
        let timer = 50;
        this.sendmsg = timer + "s";
        this.timeFun = setInterval(() => {
            --timer;
            this.sendmsg = timer + "s";
            if (timer == 0) {
                this.isSend = true;
                this.sendmsg = "重新发送";
                clearInterval(this.timeFun);
            }
        }, 1000);
    },
    async complete(e) {
      if(this.code.length>=6) {
        e.srcElement.blur(); // 让输入框主动失焦
        
        const response = 
        await axios.post('/api/web/checkVerificationCode', {
          code: this.code,
        });

        // console.log(response.data)
        if(response.data.code != 200) {
          this.isCode = false
          this.codeMsg = '验证码错误或失效'
        } else {
          this.isCode = false;
          if(this.email == response.data.data.email) {
            this.codeMsg = '验证码正确'
          } else {
            this.codeMsg = '验证码和邮箱不匹配'
          }
          
        }
      }
    },
    async handleSubmit() {
     const response = 
     await axios.post('/api/web/register', {
        username: this.username,
        email: this.email,
        code: this.code,
        password: this.password,
        password_confirmation: this.passwordConfirm,
      });

      // console.log(response.data.code);
      if(response.data.code != 200) {
        this.tigs(response.data.message, 'warning')
      } else {
        localStorage.setItem('token', response.data.data.token);
        localStorage.setItem('userId', response.data.data.userId);
        localStorage.setItem('username', response.data.data.username);
        localStorage.setItem('userDescription', response.data.data.description);
        this.tigs('注册成功', 'success')
        window.location.replace("/")
        // this.$router.push('/');
      }
    }
  }
}
</script>

<style>
.el-button {
    padding: 1.1rem;
}
</style>